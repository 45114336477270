<template>
	<div class="box shadow-none col-span-6">
		<div class=" text-left lg:text-left p-5 border-t border-gray-200 dark:border-dark-5">
			<div class="top-container">
				<div class="mr-4">
					<p class="font-bold text-xl form-label mb-0 mr-2">Archive:</p>
					<div class="mx-3">
						<div class="mb-3">
							<p class="font-bold form-label">Legal entity:</p>
							<Dropdown
								@update-value="updateSelectedLegalEntity"
								:data="legalEntitiesData"
								:selected="archive.legalEntityId"
							/>
						</div>
						<Field
							id="archiveDocId"
							title="Document ID *:"
							v-model="archive.docId"
							type="text"
						/>

						<div class="mb-3 flex flex-col">
							<p class="font-bold form-label mb-0 mr-2">Start Archiving</p>
							<button
								@click="archiveDocument('archiveDocument')"
								class="btn-bl btn btn-sm w-24 mr-1 mb-2 text-white"
								:class="{ 'bg-theme-1': !bools.archiveDocument,
                                  'bg-gray-500': bools.archiveDocument,
                                  'pointer-events-none bg-gray-500': !archive.docId || archive.docId === ''
                                  }"
							>
								Execute
							</button>
						</div>
					</div>
				</div>

				<div class="buttons-container">
					<div>
						<p class="font-bold text-xl form-label mb-0 mr-2">Restore:</p>
						<div class="mb-3">
							<h2 class="font-bold form-label mb-0 mr-2">Restore Suppliergroup users</h2>
							<div>
								<button
									@click="restoreUsers(false, 'restoreOneUser')"
									class="btn-bl btn btn-sm w-24 mr-1 mb-2 text-white"
									:class="{
										'bg-theme-1': !bools.restoreOneUser,
										'bg-gray-500': bools.restoreOneUser
										}"
								>
									Execute
								</button>
							</div>
						</div>
					</div>
					<div class="">
						<p class="font-bold text-xl form-label mb-0 mr-2">Orphaned messages:</p>
						<div>
							<h2 class="font-bold form-label mb-0 mr-2">Clear Suppliergroup messages</h2>
							<div>
								<button
									@click="clearOrphanedMessages(false, 'clearOrphanedOne')"
									class="btn-bl btn btn-sm w-24 mr-1 mb-2 text-white"
									:class="{
										'bg-theme-1': !bools.clearOrphanedOne,'bg-gray-500': bools.clearOrphanedOne
										}"
								>
									Execute
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div>
				<Accordion>
					<template v-slot:title>
						<span class="font-semibold text-lg">Download multiple documents:</span>
					</template>
					<template v-slot:content>
						<div class="ml-3">
							<vue-select
								v-model="selectedDownloadFolder"
								:options="downloadFolders"
								multiple
								@click="this.errors.downloadFolder = false"
								:clearable="false"
								style="margin-bottom: 10px; width: 20rem"
							>
								<template #selected-option="option">
									{{ option.label }}
								</template>
								<template #option="option">
									{{ option.label }}
								</template>

							</vue-select>
							<p class="error" v-if="errors.downloadFolder">Please select the folder you want to
								download</p>
							<p class="font-bold form-label mb-2">Doc ids (each id in new line)*:"</p>
							<div class="flex flex-row justify-between">

							<textarea
								rows="8"
								id="continueIngestionDocIds"
								v-model="downloadDocumentsDocId"
								@click="setError('downloadDocuments', false)"
								class="textarea-style"
								placeholder="DocNumber"
							/>
								<vue-date-picker
									v-model="downloadDocumentsDate"
									inline
									auto-apply
									:enable-time-picker="false"
									class="ml-5"
								/>
							</div>

							<p class="error" v-if="errors.downloadDocuments">Doc ids field can not be empty</p>
							<div class="mt-2">
								<button
									@click="downloadDocuments"
									class="btn-bl btn btn-sm mr-1 mb-2 text-white bg-theme-1"
								>
									Download
								</button>
							</div>
						</div>
					</template>
				</Accordion>
				<Accordion>
					<template v-slot:title>
						<span class="font-semibold text-lg">Continue ingestion:</span>
					</template>
					<template v-slot:content>
						<div class="ml-3">
							<p class="font-bold form-label mb-2">Doc ids (each id in new line)*:"</p>
							<textarea
								rows="8"
								id="continueIngestionDocIds"
								v-model="continueIngestionDocId"
								@click="setError('continueIngestion', false)"
								class="textarea-style"
							/>
							<p class="error" v-if="errors.continueIngestion">Doc ids field can not be empty</p>
							<div class="mt-2">
								<button
									@click="continueIngestion"
									class="btn-bl btn btn-sm mr-1 mb-2 text-white bg-theme-1"
								>
									Continue ingestion
								</button>
							</div>
						</div>
					</template>
				</Accordion>
				<Accordion>
					<template v-slot:title>
						<span class="font-semibold text-lg">Delete archive:</span>
					</template>
					<template v-slot:content>
						<div class="ml-3">
							<p class="font-bold form-label mb-2">Doc ids (each id in new line)*:"</p>
							<div>
                            <textarea
								rows="8"
								id="deleteFromLegalArchiveDocIds"
								v-model="deleteFromLegalArchiveDocId"
								@click="setError('deleteFromLegalArchive', false)"
								class="textarea-style"
							/>
							</div>

							<p class="error" v-if="errors.deleteFromLegalArchive">Doc ids field can not be empty</p>
							<div class="mb-3 flex mt-4">
								<label class="font-bold form-label mb-0 mr-2">Delete from legal archive:</label>
								<ToggleSlide
									class="w-min"
									@update-value="updateDeleteFromLegalArchive"
									:key="deleteFromLegalArchive"
									:bool="deleteFromLegalArchive"
								/>
							</div>
							<div class="mt-2">
								<button
									@click="deleteFromArchive"
									class="btn-bl btn btn-sm mr-1 mb-2 text-white bg-theme-1"
								>
									Delete from legal archive
								</button>
							</div>
						</div>
					</template>
				</Accordion>

				<Accordion>
					<template v-slot:title>
						<span class="font-semibold text-lg">Process failed Xml:</span>
					</template>
					<template v-slot:content>
						<div class="ml-3" v-if="renderComponent">
							<div class="width-50">
								<div>
									<p class="font-bold form-label mb-2">Doc id *:"</p>
									<div>
										<textarea
											rows="2"
											id="reprocessXml"
											v-model="reprocessXmlDocId"
											@click="setError('reprocessXml', false)"
											class="textarea-style"
										/>
									</div>
									<p class="error" v-if="errors.reprocessXml">Doc ids field can not be empty</p>
								</div>
								<div>
									<FileUpload
										@update-value="setData($event, 'xml')"
										:data="uploadedFiles.xml"
										:isForSftpMount="true"
										processFailedDocType="xml"
										id="UploadXml"
										title="Upload xml:"
										accept=".xml"
										class="mt-2"
									/>
									<FileUpload
										@update-value="setData($event, 'pdf')"
										:data="uploadedFiles.pdf"
										:isForSftpMount="true"
										processFailedDocType="pdf"
										id="uploadPdf"
										title="Upload pdf:"
										accept=".pdf"
									/>
									<div class="one-line mt-4">
										<div class="mb-3 flex items-start">
											<label class="font-bold form-label mb-0 mr-2">Add attachment:</label>
											<ToggleSlide
												class="w-min"
												@update-value="updateAddAttachment"
												:key="addAttachment"
												:bool="addAttachment"
											/>
										</div>

										<div v-if="this.addAttachment">
											<FileUpload
												@update-value="setData($event, 'attachment')"
												:data="uploadedFiles.attachment"
												:isForSftpMount="true"
												processFailedDocType="attachment"
												id="uploadAttachment"
												title="Upload attachment:"
											/>
										</div>
									</div>
								</div>
							</div>
							<div class="mt-2">
								<button
									@click="reprocessXml"
									:disabled="disableReprocessXml"
									class="btn-bl btn btn-sm mr-1 mb-2 text-white bg-theme-1"
								>
									Process Failed Xml
								</button>
							</div>
						</div>
					</template>
				</Accordion>
			</div>
		</div>
	</div>
</template>
<script>
import Field from "../Field.vue";
import Dropdown from "../Dropdown.vue";
import Accordion from "../Accordion.vue";
import ToggleSlide from "../ToggleSlide.vue";
import FileUpload from "../FileUpload.vue";
import VueSelect from "vue3-select"
import VueDatePicker from "@vuepic/vue-datepicker";
import moment from "moment";

export default {
	name: "ViewActions",
	components: {
		VueDatePicker,
		Field,
		Dropdown,
		Accordion,
		ToggleSlide,
		FileUpload,
		VueSelect
	},
	data() {
		return {
			renderComponent: true,
			legalEntitiesData: [],
			archive: {
				docId: "",
				legalEntityId: "",
			},
			bools: {},
			continueIngestionDocId: '',
			downloadDocumentsDocId: '',
			downloadDocumentsDate: '',
			reprocessXmlDocId: '',
			deleteFromLegalArchiveDocId: '',
			deleteFromLegalArchive: false,
			addAttachment: false,
			errors: {
				downloadDocuments: false,
				downloadFolder: false,
				continueIngestion: false,
				deleteFromLegalArchive: false,
				reprocessXml: false,
			},
			uploadedFiles: {
				pdf: '',
				xml: '',
				attachment: ''
			},
			downloadFolders: [
				{label: 'Documents', value: 'checkDocuments'},
				{label: 'Attachments', value: 'checkAttachments'},
				{label: 'preprocessing', value: 'checkPreProcessing'}
			],
			checkDocuments: false,
			checkAttachments: false,
			checkPreProcessing: false,
			selectedDownloadFolder: [{label: 'Select folder', value: ''}]
		}
	},
	mounted() {
		let id = 0
		this.legalEntitiesData = []
		for (let le in this.$store.state.activeSG.supplierLegalEntities) {
			this.legalEntitiesData.push({id: id, name: le})
			id++
		}
		if (this.legalEntitiesData.length) {
			this.archive.legalEntityId = this.legalEntitiesData[0].name
		}
	},
	watch: {
		startArchive(newVal) {
			if (newVal) {
				this.archive.docId = ""
				this.$store.commit("startArchive", false)
			}
		},
		selectedDownloadFolder() {
			if (this.selectedDownloadFolder.length === 0) this.selectedDownloadFolder.push({
				label: 'Select folder',
				value: ''
			})
			else {
				let element = this.selectedDownloadFolder.find(val => val.value === '')
				if (element !== undefined) {
					let elementIndex = this.selectedDownloadFolder.indexOf(element)
					delete this.selectedDownloadFolder.splice(elementIndex, 1)
				}

			}
		}
	},
	computed: {
		disableReprocessXml() {
			let disabled = false
			if (this.addAttachment) {
				if ((this.uploadedFiles.pdf === '') || (this.uploadedFiles.xml === '') || (this.uploadedFiles.attachment === '')) {
					disabled = true
				} else {
					if (!this.$store.state.sftpMountFileUploaded.pdf || !this.$store.state.sftpMountFileUploaded.xml || !this.$store.state.sftpMountFileUploaded.attachment) {
						disabled = true
					}
				}
			} else {
				if ((this.uploadedFiles.pdf === '') || (this.uploadedFiles.xml === '')) {
					disabled = true
				} else {
					if (!this.$store.state.sftpMountFileUploaded.pdf || !this.$store.state.sftpMountFileUploaded.xml) {
						disabled = true
					}
				}
			}
			return disabled
		},
		startArchive() {
			return this.$store.state.startArchive
		}
	},
	methods: {
		setData(data, type) {
			this.uploadedFiles[type] = data.value
		},
		updateDeleteFromLegalArchive(data) {
			this.deleteFromLegalArchive = data;
		},
		updateAddAttachment(data) {
			this.addAttachment = data;
		},
		setError(error, value) {
			this.errors[error] = value;
		},
		reprocessXml() {
			if (this.reprocessXmlDocId === '') {
				this.setError('reprocessXml', true)
				return
			}

			let payload = {
				supplierGroupId: this.$store.state.activeSG.supplierGroupId,
				docId: this.reprocessXmlDocId,
				xml: {
					bucketName: this.$store.state.activeSG.storageConfig.bucketConfig.sftpMount,
					fileName: 'outgoing/data/' + this.$store.state.tempFileName
				}
			}
			this.$store.dispatch('reprocessXml', payload)

			this.uploadedFiles = {
				pdf: '',
				xml: '',
				attachment: ''
			}
			this.addAttachment = false
			this.reprocessXmlDocId = ''
			this.forceRerender()
		},

		forceRerender() {
			this.renderComponent = false

			this.$nextTick(() => {
				this.renderComponent = true
			})
		},
		deleteFromArchive() {
			if (this.deleteFromLegalArchiveDocId === '') {
				this.setError('deleteFromLegalArchive', true)
				return
			}

			let docIds = this.deleteFromLegalArchiveDocId.split("\n")

			let payload = {
				formData: {
					docIds: docIds,
					deleteFromLegalArchive: this.deleteFromLegalArchive
				},
				supplierGroupId: this.$store.state.activeSG.supplierGroupId,
				token: this.$root.token
			}
			this.$store.dispatch('deleteFromLegalArchive', payload)
			this.deleteFromLegalArchiveDocId = ''
		},
		continueIngestion() {
			if (this.continueIngestionDocId === '') {
				this.setError('continueIngestion', true)
				return
			}

			let docIds = this.continueIngestionDocId.split("\n")

			let payload = {
				docIds: {docIds: docIds},
				supplierGroupId: this.$store.state.activeSG.supplierGroupId,
				token: this.$root.token
			}
			this.$store.dispatch('continueIngestion', payload)
			this.continueIngestionDocId = ''
		},
		downloadDocuments() {
			if (this.downloadDocumentsDocId === '') {
				this.setError('downloadDocuments', true)
			}
			if (this.selectedDownloadFolder[0].value === '') {
				this.setError('downloadFolder', true)
			}

			if (this.errors.downloadDocuments === true || this.errors.downloadFolder === true) return

			this.checkDocuments = false
			this.checkAttachments = false
			this.checkPreProcessing = false

			this.selectedDownloadFolder.forEach(folder => {
				this[folder.value] = true
			})

			let payload = {
				body: {
					dates: [moment(this.downloadDocumentsDate).format('YYYY-MM-DD').toString()],
					prefixes: this.downloadDocumentsDocId.split('\n'),
					checkDocuments: this.checkDocuments,
					checkAttachments: this.checkAttachments,
					checkPreProcessing: this.checkPreProcessing
				},
				brandingId: this.$store.state.activeSG.brandingId
			}
			this.$store.dispatch('downloadMultiDocuments', payload)
		},
		updateSelectedFolder(data) {
			this.selectedDownloadFolder = this.downloadFolders[data].name
		},
		updateSelectedLegalEntity(data) {
			this.archive.legalEntityId = this.legalEntitiesData[data].name
		},
		restoreUsers(isAllUsers, id) {
			this.clickEvent(id)
			if (isAllUsers) {
				this.$store.dispatch("globalActions/RestoreAllUsers")
				this.getAlert("Restored All users", "Succesfully")
			} else {
				this.$store.dispatch("globalActions/RestoreSGUsers",
					this.$store.state.activeSG.supplierGroupId
				)
				this.getAlert("Restored Suppliergroup users", "Succesfully")
			}
		},
		clearOrphanedMessages(isAll, id) {
			this.clickEvent(id)
			if (isAll) {
				this.$store.dispatch("globalActions/ClearAllOrphanedMessages")
				this.getAlert("Cleared all messages", "Succesfully")
			} else {
				this.$store.dispatch("globalActions/ClearSGOrphanedMessages", this.$store.state.activeSG.supplierGroupId)
				this.getAlert("Cleared SG messages", "Succesfully")
			}
		},
		archiveDocument(id) {
			this.clickEvent(id)
			let payload = {
				sgId: this.$store.state.activeSG.supplierGroupId,
				body: this.archive
			}

			this.$store.dispatch('globalActions/ArchiveDocuments', payload)
			this.getAlert("Archived document", "Succesfully");
		},
		clickEvent(id) {
			if (!this.bools[id]) this.bools[id] = false
			this.bools[id] = true
			setTimeout(() => {
				this.bools[id] = false
			}, 2000)
		},
		getAlert(title, text) {
			if (this.$store.state.responseStatus === 200) {
				this.$store.commit("toggleAlert", {
					title: title,
					text: text,
				})
			} else {
				this.$store.commit("toggleAlert", {
					title: title,
					text: "ERROR",
				})
			}
		}
	}
}
</script>

<style scoped>
.top-container {
	display: grid;
	grid-template-rows: 100%;
	grid-template-columns: 50% 50%;
}

.textarea-style {
	border: 1px solid rgb(226, 232, 240);
	border-radius: 4px;
	width: 100%;
}

.textarea-style:focus, .textarea-style:focus-visible {
	box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px, rgba(59, 130, 246, 0.5) 0px 0px 0px 3px, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px !important;
	border: 2px solid rgb(226, 232, 240) !important;
	outline-style: hidden !important;
}

.buttons-container {
	display: grid;
	grid-template-columns: 50% 50%;
	grid-template-rows: 100%;
}

.width-50 {
	width: 50%;
}

.one-line {
	display: grid;
	grid-template-rows: 100%;
	grid-template-columns: 200px auto;
}
</style>